import React, {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useGLTF_cloned from "../loaders/useGLTF_cloned.js";
import {
  Center,
  Text3D,
  useAnimations,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import { DEG2RAD, degToRad } from "three/src/math/MathUtils.js";
import * as THREE from "three";
import { useModelsStore } from "../components/ModelsStore.js";
import { useFrame } from "@react-three/fiber";
import {
  useRaceRunContext,
  useRaceRunnerControls,
} from "../components/RaceRunner.js";
import { dec, getv, nils, time_diff_txt } from "../utils/utils.js";
import { useNowContext } from "../App.js";
import { useRace } from "../components/Race.js";
import { pad } from "lodash";
import { metrics, sx, trackpiece_per100m } from "./metrics.js";
import { useCameraControls } from "../components/CameraControls.js";
import { p_vec3 } from "../utils/vectorutils.js";

const TrackContext = createContext();
export const useTrackContext = () => useContext(TrackContext);

const TrackPiece = ({ i = 0, ref, ...props }) => {
  const camct = useCameraControls();

  const { models } = useTrackContext();
  const trackpiece = useGLTF_cloned(models.trackpiece);
  const posx = i * 100;

  useFrame((r3fs) => {
    const rcam = getv(camct, "camera");
    if (nils(rcam)) return;
    // console.log("cam", p_vec3(rcam?.position));

    const camside =
      Math.abs(rcam.position.z) > 6 ? Math.sign(rcam.position.z) : null;

    // const scene = r3fs.scene;
    const scene = trackpiece.scene;
    if (nils(scene)) return;
    scene.traverse((object) => {
      // console.log(object.name);
      if (object.isMesh) {
        if (camside == null) {
          object.visible = true;
        } else {
          let objside = Math.sign(object.position.z);
          if (object.name.startsWith("Plane_006")) {
          } else if (
            // object.position.z !== 0 ||
            object.name.startsWith("Building") ||
            object.name.startsWith("Pyramid") ||
            object.name.startsWith("Pillar") ||
            0
          ) {
            object.visible =
              camside == null ? true : camside == objside ? false : true;
          } else if (
            // right shit
            object.name.startsWith("greif_statue_greif_1_mt") ||
            object.name.startsWith("Object_5") ||
            object.name.startsWith("Statue_2") ||
            0
          ) {
            object.visible = camside == 1 ? false : true;
          } else if (
            // left shit
            object.name.startsWith("stok_stick") ||
            0
          ) {
            object.visible = camside == -1 ? false : true;
          }
        }
        // if (object.name.startsWith("Statue 1.001") || 0) {
        //   object.visible = cam_inside_track;
        // }
      }
    });
  });

  return (
    <group ref={ref} position-x={posx} position-y={-0.5} position-z={0}>
      <primitive object={trackpiece.scene} />
    </group>
  );
};

const FloorMark = ({ atdist, txt = "aaaa", ...props }) => {
  const atglobx = atdist * sx;
  const neon = "#00FFFF";
  const textRef = useRef();
  useFrame(({ clock }) => {
    if (textRef.current) {
      // textRef.current.rotation.y = clock.elapsedTime * 1.5;
      // const my_scale = Math.sin(clock.elapsedTime) / 2 + 2.5;
      // textRef.current.position.y = my_scale;
      // textRef.current.scale.x = my_scale;
      // textRef.current.scale.y = my_scale;
      // textRef.current.scale.z = my_scale;
      // textRef.current.rotation.x += 0.1;
    }
  });

  const text3d_size = txt == "FINISH" ? 0.5 : 0.8;
  const show_mid_sheet = txt == "FINISH" ? false : true;
  const show_end_txt = txt == "FINISH" ? false : true;
  const text3d = (
    <Text3D
      font="/fonts/Terano_Regular.json"
      size={text3d_size}
      height={0.02}
      curveSegments={12}
      bevelEnabled
      bevelThickness={0.02}
      bevelSize={0.02}
      bevelSegments={5}
    >
      {txt}
      <meshStandardMaterial
        color="#000" // You can use a gold color
        roughness={0.2} // Adjust roughness for reflectivity
        metalness={0.8} // Set to 1 for a metallic appearance
        emissive={new THREE.Color("#47b3ff")}
      />
    </Text3D>
  );
  // const { scene } = useGLTF("/models/track7/meter_sign.glb");
  const box_texture = useTexture(
    "https://img.pikbest.com/wp/202343/stone-slab-texture-background-create-a-striking-wall-of-slabs_9983600.jpg!bw700",
  );
  return (
    <group position-x={atglobx}>
      <group position-y={2.5} ref={textRef}>
        <Center
          // sa
          position-z={0}
          position-x={-0.5}
          rotation-y={degToRad(-90)}
        >
          {text3d}
        </Center>
      </group>
    </group>
  );
};
const FinishLine = ({ atdist }) => {
  const atglob = atdist * sx;
  const neon = "#00ffff";
  const size = { x: 9.5, y: 4.5 };
  const checksize = { x: 0.1, y: 0.1 };

  const neontexture = useTexture("/images/neon_checkbox.png");
  const neontexture_alpha = useTexture("/images/neon_checkbox_alpha.jpg");
  for (let t of [neontexture, neontexture_alpha]) {
    t.repeat.set(size.x / checksize.x, size.y / checksize.y);
    t.wrapS = THREE.RepeatWrapping;
    t.wrapT = THREE.RepeatWrapping;
  }

  return (
    <group
      position-x={atglob}
      position-z={0}
      position-y={0}
      rotation-y={degToRad(-90)}
    >
      <mesh>
        <boxGeometry args={[size.x, size.y, 0.05]} />
        <meshStandardMaterial
          map={neontexture}
          alphaMap={neontexture_alpha}
          // emissiveMap={neontexture_emissive}
          emissive={new THREE.Color(neon)}
          emissiveIntensity={metrics?.text_emissive_intensity}
          transparent={true}
        />
      </mesh>
    </group>
  );
};
const StartLine = () => {
  const modelstore = useModelsStore();
  const { now } = useNowContext();
  const racect = useRace();
  const raceruncon = useRaceRunContext();
  const racerunningct = useRaceRunnerControls();

  const { race, racerunhs, hsob, rtstatus, starob } = racect;
  const { t_ref, runmode } = raceruncon;
  const { t, hsrunninginfo } = racerunningct;

  const r = race;

  const [timertxt, dsecfont] = useMemo(() => {
    if (nils(r)) return [null, null];
    let t = t_ref.current;
    if (nils(t) || t >= 0) return [null, false];
    let [ar, highnil] = time_diff_txt(0, -t * 1000);
    highnil = Math.min(highnil, 1);
    let txt = [];
    if (rtstatus == "open") {
      return ["Race is OPEN", false];
    }
    if (rtstatus == "cancelled") {
      return ["Race is cancelled", false];
    } else if (rtstatus == "scheduled") {
      txt = [];
      ar = ar.slice(highnil, ar.length - 1);
      for (let [a, b, c] of ar) txt.push(`${pad(a, 2)} ${c}`);
      txt = txt.join(" ");
      return [txt, false];
    } else {
      ar = ar.slice(ar.length - 3, ar.length - 1);
      txt = [];
      for (let [a, b] of ar) txt.push(`${pad(a, 2)}`);
      txt = txt.join(":");
      return [txt, true];
    }
    return [null, false];
  }, [t, now, r]);
  // console.log(timertxt);
  const stline = useGLTF_cloned(modelstore.get_model("startline_gates2"));
  const text3d = (
    <Text3D
      font="./fonts/Audiowide_Regular.json"
      size={0.5}
      height={0.05}
      curveSegments={12}
      bevelEnabled
      bevelThickness={0.02}
      bevelSize={0.02}
      bevelSegments={5}
    >
      {timertxt ? timertxt.trim()[timertxt.trim().length - 1] : "GO"}
      <meshStandardMaterial
        color="#fff" // You can use a gold color
        roughness={0.2} // Adjust roughness for reflectivity
        metalness={0.8} // Set to 1 for a metallic appearance
        emissive={new THREE.Color("#fff")}
      />
    </Text3D>
  );

  const [s, set_s] = useState({
    show_st_txt: false,
    show_mid_sheet: false,
    show_end_txt: true,
  });

  const gate_banner_ref = useRef(null);
  const countdown_ref = useRef(null);
  useFrame((r3fs) => {
    let rcam = r3fs.camera;
    if (nils(rcam)) return;
    let x = rcam.position.x;
    // console.log("cam", p_vec3(rcam?.position));
    if (x < 0) {
      set_s({ ...s, show_end_txt: true, show_st_txt: false });
      gate_banner_ref.current.rotation.y = degToRad(-90);
      countdown_ref.current.rotation.y = degToRad(-90);
    } else {
      set_s({ ...s, show_end_txt: false, show_st_txt: true });
      gate_banner_ref.current.rotation.y = degToRad(90);
      countdown_ref.current.rotation.y = degToRad(90);
    }
  });

  const gate = {
    scale: 0.78,
  };
  return (
    <group>
      {s.show_mid_sheet && (
        <mesh position-y={2.5}>
          <boxGeometry args={[0.01, 1.5, 3]} />
          <meshBasicMaterial color={"#000000"} />
        </mesh>
      )}
      <Center
        ref={countdown_ref}
        position-z={-0.35}
        position-x={+0.1}
        rotation-y={degToRad(90)}
        position-y={2.5}
      >
        {text3d}
      </Center>

      <Center
        // sa
        position-z={0.35}
        position-x={-0.1}
        position-y={1}
        ref={gate_banner_ref}
      >
        <Text3D
          font="./fonts/Terano_Regular.json"
          size={0.4}
          height={0.02}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelSegments={5}
        >
          {"Get Ready !!!"}
          <meshStandardMaterial
            color="#fff" // You can use a gold color
            roughness={0.2} // Adjust roughness for reflectivity
            metalness={0.8} // Set to 1 for a metallic appearance
            emissive={new THREE.Color("#fff")}
          />
        </Text3D>
      </Center>

      <group scale={gate.scale} position={[0, 0.05, 0]}>
        <primitive object={stline.scene} />
      </group>
    </group>
  );
};

const Billboard = ({ atdist }) => {
  const { models } = useTrackContext();
  const bm = useGLTF_cloned(models.billboard);
  return (
    <group
      position-x={atdist * sx}
      position-z={-10}
      position-y={0}
      rotation-y={degToRad(-75)}
    >
      <group scale={0.3}>
        <primitive object={bm.scene} />
      </group>
    </group>
  );
};

const Track = ({ dist = 2200, ...props }) => {
  const models_store = useModelsStore();
  const trackpiece_gltf = models_store.get_model("track_trackpiece");

  const rrunct = useRaceRunnerControls();
  const { t, max_cov } = rrunct;

  const models = {
    trackpiece: trackpiece_gltf,
  };

  const tcon = {
    models,
  };

  const floor_marks = useMemo(() => {
    let seg = 100;
    let n = dist / seg + 1;
    let ar = [...Array(n)].map((e, i) => {
      let d = i * seg;
      let dtxt =
        (d == 0 && "START") || (d == dist && "FINISH") || `${dist - d}M`;

      return [d, dtxt];
    });
    ar = ar.slice(1);
    return ar;
  }, [dist]);

  const trackpieces_n = 12;
  const tracpieces_group_ref = useRef(null);

  const [convmap, set_convmap] = useState({ dist: 0 });
  const move_trackpieces = () => {
    console.log("move_trackpieces", { t, max_cov });
    let ref = tracpieces_group_ref.current;
    if (nils(ref)) {
      return;
    }
    let posx = max_cov * sx;
    ref.position.x = posx;
    set_convmap({ dist: max_cov });
  };
  useFrame(() => {
    let distdiff = max_cov - convmap.dist;
    if (distdiff > 200) {
      move_trackpieces();
    }
  });

  return (
    <TrackContext.Provider value={tcon}>
      <StartLine />

      {floor_marks.map(([atdist, txt], i) => {
        return (
          <FloorMark
            {...{
              key: i,
              atdist: atdist,
              txt,
            }}
          />
        );
      })}

      <group position={[0, 0.5, 0]} ref={tracpieces_group_ref}>
        {[...Array(trackpieces_n)].map((e, i) => {
          return <TrackPiece {...{ key: i, i: i - 2 }} />;
        })}
        <SpeedLines {...{ t, max_cov, dist }} />
      </group>

      <FinishLine atdist={dist} />
    </TrackContext.Provider>
  );
};

const SpeedLine = forwardRef(({ position }, ref) => {
  return (
    <>
      <mesh position={position} ref={ref}>
        <boxGeometry args={[1.5, 0.02, 0.02]} />
        <meshStandardMaterial color={"#FFFFFF"} />
      </mesh>
    </>
  );
});

const SpeedLines = ({ t, max_cov, dist }) => {
  const lines_n = 35;
  const [lines, set_lines] = useState([]);
  const lines_ref = useRef([]);
  const r = {
    x: 100 * sx,
    y: 4,
    z: 8,
  };
  const gen_lines = () => {
    console.log("speed:gen_lines");
    let ls = [];
    for (let i = 0; i < lines_n; i++) {
      const position = [
        Math.random() * r.x, // x position
        Math.abs(Math.random() - 0.5) * r.y - 0.2, // y position
        (Math.random() - 0.5) * r.z, // z position
      ];
      ls.push(position);
    }
    set_lines(ls);
    lines_ref.current = new Array(lines_n).fill().map(() => React.createRef());
  };
  useEffect(() => {
    gen_lines();
  }, []);

  const mode = useMemo(() => {
    if (t <= 0) return "stopped";
    if (max_cov < dist) return "running";
    return "finished";
  }, [t, max_cov, dist]);

  useEffect(() => {
    let last_lr = getv(lines_ref, "current.0.current");
    // console.log("SpeedLines", mode, last_lr);
    let lrs = lines_ref.current;

    for (let i = 0; i < lines_n; i++) {
      let lr = lrs[i].current;
      // console.log("lr", i, lr);
      if (lr) {
        if (mode == "running") lr.visible = true;
        else lr.visible = false;
      }
    }
  }, [mode, getv(lines_ref, "current.0.current")]);

  return (
    <>
      {lines.map((pos, i) => {
        let ref = lines_ref.current[i];
        return (
          <SpeedLine key={pos} position={pos} ref={lines_ref.current[i]} />
        );
      })}
    </>
  );
};

export default Track;
